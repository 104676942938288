import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'Amplify';
import '@lifepowr/components/src/App.scss';
import store from "@lifepowr/components/src/store";
import LandingPage from "components/landing-page";
import withAuth from '@lifepowr/components/src/utils/auth/checkLogin';
import { ThemeProvider, createTheme, /* makeStyles */ } from '@mui/material/styles';
import getClientInfo from "@lifepowr/components/src/clients";

const theme = createTheme(
  {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  },
);

const clientInfo = getClientInfo();
if (clientInfo && clientInfo.title) {
    window.document.title = clientInfo.title;
}

function App() {
  return (
  	<ThemeProvider theme={theme}>
	  	<Provider store={store}>
				<Router basename={process.env.REACT_APP_BASENAME || ''}>
					<LandingPage />
				</Router>
			</Provider>
		</ThemeProvider>
  );
}

export default withAuth(App, 'partner');
